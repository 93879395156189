var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "文件预览",
        visible: _vm.value,
        "before-close": _vm.onHandleClose,
        "close-on-click-modal": true,
        "append-to-body": true,
      },
      on: {
        "update:visible": function ($event) {
          _vm.value = $event
        },
        opened: _vm.onSetActiveItem,
      },
    },
    [
      _c(
        "div",
        { staticClass: "block" },
        [
          _c(
            "el-carousel",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "refCarousel",
              class: { "hide-btn": _vm.fileList.length <= 1 },
              attrs: { trigger: "click", autoplay: false },
            },
            _vm._l(_vm.fileList, function (item, index) {
              return _c("el-carousel-item", { key: index }, [
                _vm.imageType.includes(item.fileType)
                  ? _c("img", { attrs: { src: item.url, alt: item.name } })
                  : _vm._e(),
                _vm.videoType.includes(item.fileType)
                  ? _c(
                      "div",
                      {
                        ref: "videoPlayer",
                        refInFor: true,
                        staticClass: "video-wrapper",
                        attrs: { id: "playerId" },
                      },
                      [
                        _c("video-player", {
                          ref: "videoPlayer",
                          refInFor: true,
                          staticClass: "video-player vjs-custom-skin",
                          attrs: {
                            playsinline: true,
                            options: _vm.videoPlayerOptions(item.url),
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ])
            }),
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }